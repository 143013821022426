import React, { useState } from 'react'
import { Text, Title, TitleBlock } from '../commonStyled'
import { colors, fontFamily } from '../../UIKit'

import './MemberNotification.css'

export const MemberNotification = () => {
  const [isClicked, setIsClicked] = useState(false)

  const handleClick = () => {
    setIsClicked(!isClicked)
  }

  return (
    <div>
      <button
        style={{
          backgroundColor: '#FFFF8F',
          padding: '10px',
          border: 'none',
          borderRadius: '30px',
          marginBottom: '20px',
        }}
        onClick={handleClick}
      >
        <Text $ff={fontFamily.redHat.semiBold} $fsz={20} $lh={121} $ls={2.4} $tt='uppercase' color={colors.black}>
          Click here if you are an existing member!
        </Text>
      </button>
      <div className={`message ${isClicked ? 'visible' : ''}`} style={{ padding: '20px', backgroundColor: 'white' }}>
        <Title $fs={13}>Exclusive Offer for Our Valued Members!</Title>
        <Text $fsz={17} $lh={150}>
          <span style={{ weight: 'bold' }}>Current Members Only:</span> Your loyalty has shaped All You Can Face, and
          we're offering something special just for you.
        </Text>
        <Text $fsz={17} $lh={150}>
          Choose the new price plan, or opt for something priceless: a personal consultation with Anastasia, our
          founder.
        </Text>
        <Text $fsz={17} $lh={150}>
          This session, valued at $500, is yours for free, as a token of our appreciation.
        </Text>
        <Text>Don't miss this unique opportunity for personalized advice and insights.</Text>
        <Text $fsz={17} $lh={150}>
          Email us at mail@allyoucanface.com with the subject "Private Session with Anastasia" to secure your spot or
          for any further questions on your current subscription.
        </Text>
        <Text $fsz={17} $lh={150}>
          Thank you for being the heart of our community.
        </Text>
      </div>
    </div>
  )
}
