import React from "react";

import {
  DocumentUpdated,
  LinkItem,
  LinksWrapper,
  StyledLink,
  StyledSubtitle,
  StyledTitle,
} from "../termsAndConditions/styled";
import { policyDescription, privacyPolicyData } from "./data";
import { Container, Description } from "./styled";
import {
  GDPR,
  InterpretationAndDefinitions,
  UsingPersonalData,
  ProcessingPersonalData,
  FacebookFanPage,
  CCPAPrivacyNotice,
  DoNotTrackPolicy,
  YourCaliforniaPrivacy,
  RightsForMinorUsers,
  ChildrensPrivacy,
  LinksToOtherWebsites,
  ChangesToPolicy,
  ContactUs,
  DeletingFlow
} from "./index";

export const PrivacyPolicy = () => {
  return (
    <Container>
      <StyledTitle>Privacy policy</StyledTitle>
      <StyledSubtitle>Table of contents</StyledSubtitle>
      <LinksWrapper>
        {privacyPolicyData.map((item, index) => (
          <LinkItem key={index}>
            <StyledLink href={`/privacy-policy#${item.id}`}>
              {item.title}
            </StyledLink>
          </LinkItem>
        ))}
      </LinksWrapper>
      <Description>{policyDescription}</Description>
      <InterpretationAndDefinitions />
      <UsingPersonalData />
      <ProcessingPersonalData />
      <GDPR />
      <FacebookFanPage />
      <CCPAPrivacyNotice />
      <DoNotTrackPolicy />
      <YourCaliforniaPrivacy />
      <RightsForMinorUsers />
      <ChildrensPrivacy />
      <LinksToOtherWebsites />
      <DeletingFlow />
      <ChangesToPolicy />
      <ContactUs />

      <DocumentUpdated>
        This document was last updated on June 25, 2023
      </DocumentUpdated>
    </Container>
  );
};
