import React from "react";

import { SUBSCRIPTION_BUTTON_URL, WINDOW_WIDTH } from "../../lib/constants";
import { colors, fontFamily } from "../../UIKit";
import { Button, Text, Title } from "../commonStyled";
import {
  Advantage,
  Advantages,
  StyledButton,
  StyledText,
  Subscription,
} from "./styled";

export const SubscriptionItem = ({ sub }) => {
  const handleNavigate = () => {
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: "select_item",
      ecommerce: {
        currency: "USD",
        value: sub.eventValue,
        items: [
          {
            item_id: sub.eventItemId,
            item_name: sub.title,
            affiliation: "Google Merchandise Store",
            coupon: "",
            discount: 0,
            index: 0,
            item_brand: "All you can face",
            item_category: "Self care",
            item_variant: sub.eventItemId,
            location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
            price: sub.eventValue,
            quantity: 1,
          },
        ],
      },
    });
    window.open(SUBSCRIPTION_BUTTON_URL, "_blank");
  };

  return (
    <div>
      {sub.recommended && (
        <StyledButton
          $width={137}
          $pt={8}
          $pb={8}
          $pl={16}
          $pr={16}
          $lh={125}
          $fsz={11}
          $ls={1.76}
          $bg={colors.primary[500]}
        >
          recommended
        </StyledButton>
      )}
      <Subscription $recommended={sub.recommended}>
        <Title $fs={19}>{sub.title}</Title>
        <Text $pb={WINDOW_WIDTH > 768 ? 72 : 56} color={colors.gray[600]}>
          {sub.perMonth}
        </Text>
        <Text
          $pb={8}
          $ff={fontFamily.redHat.bold}
          $fsz={48}
          $lh={116}
          color={colors.black}
        >
          {sub.newPrice}
        </Text>
        <Text
          $pb={8}
          $ff={fontFamily.redHat.bold}
          $fsz={48}
          $lh={116}
          $crossedOut
          color={colors.primary[500]}
        >
          {sub.price}
        </Text>
        <StyledText
          $pb={16}
          $ff={fontFamily.redHat.semiBold}
          $fsz={15}
          $lh={116}
          $tt="uppercase"
          color={colors.primary[500]}
        >
          {sub.period}
        </StyledText>
        <Advantages>
          {sub.advantages.map((adv, index) => (
            <Advantage key={index}>
              <Text>{adv.name}</Text>
            </Advantage>
          ))}
        </Advantages>
        <Button onClick={handleNavigate} $pl={24} $pr={24} $pt={18} $pb={18}>
          get started
        </Button>
      </Subscription>
    </div>
  );
};
