export const subscriptionsData = [
  {
    title: "Glow & Go!",
    perMonth: "$21.45 / mo.",
    newPrice: "$4.99",
    price: "$7.99",
    period: "weekly",
    eventValue: 7.99,
    eventItemId: "week",
    recommended: false,
    advantages: [
      {
        name: "A latte less, a glow more - see the quick effects of face yoga.",
      },
      { name: "Perfect for starters - try without a long-term commitment." },
      {
        name: "Experience the refreshment and feel the difference in just a week.",
      },
    ],
  },
  {
    title: "Radiant Revolution",
    perMonth: "$4.20 / mo.",
    newPrice: "$49.99",
    price: "$249.99",
    period: "ANUALLY",
    eventValue: 249.99,
    eventItemId: "annual",
    recommended: true,
    advantages: [
      {
        name: "Step away from invasive treatments - invest in long-lasting wellness.",
      },
      { name: "Full year access to all resources and exercises." },
      {
        name: "Make face yoga a part of your daily routine and enjoy enduring transformation. ",
      },
    ],
  },
  {
    title: "Luminous Challenge",
    perMonth: "$9.99 / mo.",
    newPrice: "$9.99",
    price: "$24.99",
    period: "monthly",
    eventValue: 24.99,
    eventItemId: "month",
    recommended: false,
    advantages: [
      {
        name: "Dive deeper into the world of face yoga and become a pro.",
      },
      { name: "Shift to a natural and sustainable skincare routine." },
      {
        name: "Witness the gradual transformation - a new glow each day. ",
      },
    ],
  },
];
